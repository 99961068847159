import { startMetricsLoadBalanceLatency } from "../opentelemetry/instrumentation"
import { createSpan, endSpan } from "../opentelemetry/otel-instrumentation";
import { SpanStatusCode } from '@opentelemetry/api';

let initLoadBalancingTsPlusCount = 0;

export function ShouldUseMegaLoadBalancer(parent) {
    const span = createSpan('ShouldUseMegaLoadBalancer', parent)
    const now = new Date();
    const weekday = now.getDay();
    const hour = now.getHours();
    const hourStartMegaLoadBalance = window.hourStartMegaLoadBalance ?? 16;
    endSpan(span)
    return [0, 6].includes(weekday) || hour >= hourStartMegaLoadBalance;
}

// criar uma abstração para tratar quando está executando por dentro dos scripts do TSPlus
export async function initLoadBalancingTsPlus(parent) {
    initLoadBalancingTsPlusCount += 1;
    const span = initLoadBalancingTsPlusCount === 1 ? createSpan('initLoadBalancingTsPlus', parent) : createSpan('initLoadBalancingTsPlus');

    try {
        const result = await callbalancer(false, span);
        const hostname = getServerUrl(result, span)
        window.serversListingType = "loadbalanced";
        window.resetDropDownMenu();
        window.addServerToDropDownMenu(hostname, result, 443);
        window.disableDropDownMenu();
    } catch (error) {
        if (error === "loadbalancing-off") {
            span.setStatus({
                code: SpanStatusCode.ERROR,
                message: `loadbalancing-off`
            });
            window.serversListingType = "userassigned";
            window.checkLogin();
        }
        if (span) {
            span.setStatus({
                code: SpanStatusCode.ERROR,
                message: `${error}`
            });
            endSpan(span)
        }
        throw error;
    }
    if (span) endSpan(span)
}

export function callbalancer(isSSO, parent) {
    const span = createSpan('callbalancer', parent)
    const isMgLoadbalance = !window.isTsPlusLoadBalanceOnly && ShouldUseMegaLoadBalancer(span);
    const farmName = window.farmName = typeof window.farmName === "undefined" ? "" : window.farmName;

    const spanGetBalancer = createSpan('GET', span)
    window.xhrLoadBalancing = createNewXMLHttpRequest(span);
    const urlString = isMgLoadbalance ? new URL(`http://172.20.253.14/best-server/farm?farm_name=Farm4`) : new URL(`https://${document.location.host}/cgi-bin/hb.exe?action=lb`);
    const params = isMgLoadbalance ? null : `action=lb&t=${new Date().getTime()}&d=${document.getElementById("Editbox3").value}&l=${(page_configuration["is_webcredentials"] ? "@" : "")}${document.getElementById("Editbox1").value}`;
    window.xhrLoadBalancing.open("GET", urlString, true);
    window.xhrLoadBalancing.send(params);

    spanGetBalancer.setAttribute('http.method', 'GET');
    spanGetBalancer.setAttribute('http.url', urlString.toString());
    spanGetBalancer.setAttribute('http.target', urlString.pathname + urlString.search);
    spanGetBalancer.setAttribute('http.host', urlString.host);

    endSpan(span)
    return new Promise((resolve, reject) => {
        window.xhrLoadBalancing.onreadystatechange = isSSO ? onReadyStateChangeSSO(resolve, reject, spanGetBalancer) : onReadyStateChangeTsPlus(resolve, reject, spanGetBalancer);
    });
}

function createNewXMLHttpRequest(parent) {
    const span = createSpan('createNewXMLHttpRequest', parent)
    try {
        endSpan(span)
        return new XMLHttpRequest();
    } catch (e) {
        span.setStatus({
            code: SpanStatusCode.ERROR,
            message: `${e}`
        });
        endSpan(span)
    }
    try {
        endSpan(span)
        return new ActiveXObject('MSXML2.XMLHTTP');
    } catch (e) {
        span.setStatus({
            code: SpanStatusCode.ERROR,
            message: `${e}`
        });
        endSpan(span)
    }
    endSpan(span)
    return new ActiveXObject('Microsoft.XMLHTTP');
}

function getServerUrl(url, parent) {
    const span = createSpan('getServerUrl', parent)
    const urlSplited = url.split("~~");
    endSpan(span)
    return urlSplited[urlSplited.length - 1];
}

// Metodo chamado quando não é SSO e o LB for Mega
function onReadyStateChangeTsPlus(resolve, reject, parent) {
    return () => {
        if (window.xhrLoadBalancing.readyState == 4) {
            parent.setAttribute('http.status.code', window.xhrLoadBalancing.status)
            parent.setAttribute('http.response_text', window.xhrLoadBalancing.responseText);
            if (window.xhrLoadBalancing.status == 200) {
                window.xhrLoadBalancing.onload = () => {
                    const entries = performance.getEntriesByType("resource");
                    const filteredEntries = entries.filter(function (entry) {
                        return entry.initiatorType == "xmlhttprequest" && entry.nextHopProtocol == 'http/1.1';
                    });
                    const lastEntry = filteredEntries[filteredEntries.length - 1] || {};
                    startMetricsLoadBalanceLatency(window.xhrLoadBalancing.status, lastEntry.duration, "mega-lb");
                }
                let balancer = JSON.parse(window.xhrLoadBalancing.responseText);
                parent.end()
                resolve(`${document.location.host}/~~${balancer.hostname}`);
            }
            parent.setStatus({
                code: SpanStatusCode.ERROR,
                message: `Unexpected status code: ${window.xhrLoadBalancing.status}`
            });
            parent.setAttribute('error.message', `Request failed with status code ${window.xhrLoadBalancing.status}`);
            parent.setAttribute('error.type', 'HTTP_ERROR');
            parent.end()
            reject(window.xhrLoadBalancing.responseText);
        }
    };
}

function onLoadRequestLatencyMetric() {
    const entries = performance.getEntriesByType("resource");
    const filteredEntries = entries.filter(function (entry) {
        return entry.initiatorType == "xmlhttprequest" && entry.nextHopProtocol == 'http/1.1';
    });
    const lastEntry = filteredEntries[filteredEntries.length - 1];
    startMetricsLoadBalanceLatency(window.xhrLoadBalancing.status, lastEntry.duration, "mega-lb");
}


// Metodo chamado quando é SSO indiferente do LB
function onReadyStateChangeSSO(resolve, reject, parent) {
    return () => {
        if (window.xhrLoadBalancing.readyState === 4 && window.xhrLoadBalancing.status === 200) {
            parent.setAttribute('http.status.code', window.xhrLoadBalancing.status)
            parent.setAttribute('http.response_text', window.xhrLoadBalancing.responseText);
            const isJson = window.xhrLoadBalancing.getResponseHeader("content-type").includes("json");
            let balancer = '';
            if (isJson) {
                window.xhrLoadBalancing.onload = onLoadRequestLatencyMetric;
                const json = JSON.parse(window.xhrLoadBalancing.responseText);
                balancer = `${document.location.host}/~~${json.hostname}`;
            } else {
                balancer = window.xhrLoadBalancing.responseText.split('|')[2];
            }
            parent.end()
            resolve(balancer);
        } else if (window.xhrLoadBalancing.readyState === 4 && window.xhrLoadBalancing.status != 200) {
            parent.setStatus({
                code: SpanStatusCode.ERROR,
                message: `Unexpected status code: ${window.xhrLoadBalancing.status}`
            });
            parent.setAttribute('error.message', `Request failed with status code ${window.xhrLoadBalancing.status}`);
            parent.setAttribute('error.type', 'HTTP_ERROR');
            parent.end()
            reject(window.xhrLoadBalancing.responseText);
        }
    };
};

