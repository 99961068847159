import { LoadScriptBody, LoadScript } from "./../common";
import IsSSODomain, {isHomolog} from "./../sso/common";
import { createSpan, endSpan } from "../opentelemetry/otel-instrumentation";

export default function AddRecaptcha(parent) {
  const span = createSpan('AddRecaptcha',parent)
  window.AddRecaptcha = span
  if (!IsSSODomain(span)) {
    isHomolog(span) ? LoadScript(
      span,
      "https://www.google.com/recaptcha/enterprise.js?render=6LegdsclAAAAAHEwbhjaEvM1WTnbdaq4du_Hb4f4",
      true
    ) : LoadScript(
      span,
      "https://www.google.com/recaptcha/enterprise.js?render=6LdlVc8nAAAAAAilZ2liXz6EgD3zcIPPL5D9a2N-",
      true
    );
    const divRecaptcha = document.createElement("div");
    divRecaptcha.id = "recaptcha";
    const div = document.getElementById("logonformid");
    div.appendChild(divRecaptcha);
    const small = document.createElement("small");
    small.classList.add("text-muted");
    const buttonPoliticaPrivacidade = document.createElement("a");
    buttonPoliticaPrivacidade.href = "https://policies.google.com/privacy";
    buttonPoliticaPrivacidade.target = "_blank";
    buttonPoliticaPrivacidade.innerText = "Política de Privacidade";
    const buttonTermoDeServico = document.createElement("a");
    buttonTermoDeServico.href = "https://policies.google.com/terms";
    buttonTermoDeServico.target = "_blank";
    buttonTermoDeServico.innerText = "Termos de Serviço";

    small.innerHTML =
      "Este site é protegido pelo reCAPTCHA e a " +
      buttonPoliticaPrivacidade.outerHTML +
      " e os " +
      buttonTermoDeServico.outerHTML +
      " do Google são aplicáveis.";

    div.appendChild(small);
    import("./recaptchaEvents");
  }
  endSpan(span)
}
