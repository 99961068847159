import { createSpan, endSpan } from "../opentelemetry/otel-instrumentation";

export default function GetHelm(parent) {
    const span = createSpan('GetHelm', parent)
    let result = FindInQueryString(span);
    if (!result)
        result = FindInHost(span);
    if (!result)
        result = 'sso';
    endSpan(span)
    return result;
}

function FindInQueryString(parent) {
    const span = createSpan('FindInQueryString', parent)
    var paramsBusca = new URLSearchParams(window.location.search);
    if (paramsBusca.has('realm')) {
        endSpan(span)
        return paramsBusca.get('realm');
    } else {
        endSpan(span)
        return null;
    }
}

function FindInHost(parent) {
    const span = createSpan('FindInHost',parent)
    const principalDomain = GetPrincipalDomain(span);
    endSpan(span)
    return trimEnd(window.location.host.replace(principalDomain, ''), '.',span);
}


function GetPrincipalDomain(parent) {
    const span = createSpan('GetPrincipalDomain',parent)
    const parts = window.location.host.split('.');
    endSpan(span)
    return parts
        .slice(-(parts.length === 4 ? 3 : 2))
        .join('.');
}

function trimEnd(content, charToremove, parent) {
    const span = createSpan('trimEnd',parent)
    if (content.endsWith(charToremove)) {
        content = content.slice(0, -1);
        endSpan(span)
        return content;
    }
}
