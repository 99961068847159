import { createSpan, endSpan } from "../../opentelemetry/otel-instrumentation";
import { SpanStatusCode } from '@opentelemetry/api';

export async function verifyTenantStatus(accessType, loginIdentifier, parent) {
    const span = createSpan('verifyTenantStatus', parent)
    try {
        const myHeaders = new Headers();
        myHeaders.append("x-api-key", process.env.x_api_key);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "data": {
                type: accessType,
                identifier: loginIdentifier
            }
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        const spanPostVerifyTenant = createSpan('POST', span)
        spanPostVerifyTenant.setAttribute('http.method', 'POST');
        spanPostVerifyTenant.setAttribute('http.url', `${process.env.mcm_endpoint}/checkTenantIsAvailable`);

        const response = await fetch(`${process.env.mcm_endpoint}/checkTenantIsAvailable`, requestOptions);

        if (!response.ok) {
            spanPostVerifyTenant.setStatus({
                code: SpanStatusCode.ERROR,
                message: `Unexpected status code: ${response.status}`
            });
            spanPostVerifyTenant.setAttribute('error.message', `Request failed with status code ${response.status}`);
            spanPostVerifyTenant.setAttribute('error.type', 'HTTP_ERROR');
            spanPostVerifyTenant.setAttribute('http.status.code', response.status)
            spanPostVerifyTenant.setAttribute('http.response_text', response.statusText);
            endSpan(spanPostVerifyTenant)
            throw new Error(`checkTenantIsAvailable | Status: ${response.status}`)
        }

        spanPostVerifyTenant.setAttribute('http.status.code', response.status)
        spanPostVerifyTenant.setAttribute('http.response_text', response.statusText);
        endSpan(spanPostVerifyTenant)

        const json = await response.json();
        endSpan(span)
        return json.result;

    } catch (error) {
        console.error(error);
        span.setStatus({
            code: SpanStatusCode.ERROR,
            message: `${error}`
        });
        endSpan(span)
        return true;
    }
}