import GetRealm from "./realm";
import { createSpan, endSpan } from "../opentelemetry/otel-instrumentation";
import { SpanStatusCode } from '@opentelemetry/api';

function ReadConfig(parent) {
  const span = createSpan('ReadConfig', parent)
  try {
    var config = ssoConfiguration || {};
    endSpan(span)
    return config;
  } catch (e) {
    console.log(e);
    span.setStatus({
      code: SpanStatusCode.ERROR,
      message: `${e}`
    });
    endSpan(span)
  }
}

export function isHomolog(parent) {
  const span = createSpan('isHomolog', parent)
  endSpan(span)
  return window.location.host.includes("fireq.io");
}

export default function IsSSODomain(parent) {
  const span = createSpan('IsSSODomain', parent)
  const config = ReadConfig(span);
  const realm = GetRealm(span);
  endSpan(span)
  return config[realm]?.isSSO || false;
}
