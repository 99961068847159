import _ from "lodash";
import IsSSODomain from "./common";
import ExecSSO from "./ExecInit";
import { createSpan, endSpan } from "../opentelemetry/otel-instrumentation";

export default async function LoadAllDependencies(parent) {
  const span = createSpan('LoadAllDependencies', parent)
  if (IsSSODomain(span)) {
    await import("./css/style.css");
    ExecSSO(span);
  }
  endSpan(span)
}
