import { startMetricsLoginLatency, startMetricsLoginFail, startMetricsLoginSucess } from "../opentelemetry/instrumentation"
import { createSpan, endSpan } from "../opentelemetry/otel-instrumentation";
/**
 * Mesmo código do TSPlus porém instrumentado para gerar métricas
 */
export default function loginDefaultCustomNativeFunctions(parent) {
    const span = createSpan('loginDefaultCustomNativeFunctions', parent)
    const validateCredentialsCallbackBackup = window.validateCredentialsCallback

    window.validateCredentialsCallback = function () {
        if (window.xhrPassword.readyState == 4) {
            if (window.xhrPassword.status == 200) {
                const responseTextsFail = ["ko\r\n", "disabled-by-security-check\r\n", "{\"Status\": \"ko\"}", "too-many-failed-attempts\r\n"];
                const responseTextsSucess = ["ok\r\n", "{\"Status\": \"ok\"}"]

                window.xhrPassword.onload = function () {
                    var entries = performance.getEntriesByType("resource");
                    var filteredEntries = entries.filter(function (entry) {
                        return entry.initiatorType == "xmlhttprequest" && entry.nextHopProtocol == 'http/1.1';
                    });
                    var lastEntry = filteredEntries[filteredEntries.length - 1];
                    startMetricsLoginLatency(window.xhrPassword.status, lastEntry.duration);
                }

                if (responseTextsFail.includes(window.xhrPassword.responseText)) {
                    startMetricsLoginFail(window.xhrPassword.responseText, window.xhrPassword.status);
                }
                else if (responseTextsSucess.includes(window.xhrPassword.responseText)) {
                    startMetricsLoginSucess(window.xhrPassword.status);
                }

            } else {
                startMetricsLoginFail(window.xhrPassword.responseText, window.xhrPassword.status);
            }
        }
        validateCredentialsCallbackBackup();
    }
    endSpan(span)
}