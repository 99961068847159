import { startMetricsLoadBalanceFail, startMetricsLoadBalanceSucess, startMetricsLoadBalanceLatency } from "../opentelemetry/instrumentation"
import { createSpan, endSpan } from "../opentelemetry/otel-instrumentation"
/**
 * Mesmo código do TSPlus porém instrumentado para gerar métricas
 */
export default function loadbalanceCustomNativeFunctions(parent) {
    const span = createSpan('loadbalanceCustomNativeFunctions', parent)
    const processLoadBalancingBackup = window.processLoadBalancing

    window.processLoadBalancing = function () {
        if (window.xhrLoadBalancing.readyState == 4) {
            if (window.xhrLoadBalancing.status == 200) {
                window.xhrLoadBalancing.onload = function () {
                    var entries = performance.getEntriesByType("resource");
                    var filteredEntries = entries.filter(function (entry) {
                        return entry.initiatorType == "xmlhttprequest" && entry.nextHopProtocol == 'http/1.1';
                    });
                    var lastEntry = filteredEntries[filteredEntries.length - 1];
                    startMetricsLoadBalanceLatency(window.xhrLoadBalancing.status, lastEntry.duration, "ts-plus");
                }

                if (window.xhrLoadBalancing.responseText == "loadbalancing-off") {
                    startMetricsLoadBalanceFail(window.xhrLoadBalancing.responseText, window.xhrLoadBalancing.status);
                } else {
                    let s = window.xhrLoadBalancing.responseText.split("|");
                    let lessLoadedServerName = s[1];
                    startMetricsLoadBalanceSucess(lessLoadedServerName, window.xhrLoadBalancing.status);
                }
            } else {
                startMetricsLoadBalanceFail(window.xhrLoadBalancing.responseText, window.xhrLoadBalancing.status);
            }
        }
        processLoadBalancingBackup();
    }
    endSpan(span)
}